<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <div>

      <div
        v-for="(item) in data"
        :key="item"
        class="card p-2"
      >
        <div class="d-flex justify-content-between align-items-center">
          <h3
            v-if="item.type === 1"
            class="font-weight-bolder mb-0"
          >
            กล่องสุ่มเลขชุด ({{ item.count }} ชุด)
          </h3>
          <h3
            v-else
            class="font-weight-bolder mb-0"
          >
            กล่องสุ่มเลขท้าย 2 ตัว ({{ item.count }} ชุด)
          </h3>

          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="item.status"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>

            <button
              class="btn btn-gradient-success ml-25"
              @click="SaveSetting(item)"
            >
              บันทึกข้อมูล
            </button>
          </div>
        </div>

        <hr>

        <div class="mt-1">
          <div class="row">
            <div class="col">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="basic-amount"
                  v-model="item.price"
                  type="number"
                  placeholder="จำนวนเงิน"
                  :disabled="item.status == 0"
                  class="border-radius-0"
                />
                <b-input-group-append is-text>
                  ₩
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="col">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="basic-percent"
                  v-model="item.percent"
                  type="number"
                  placeholder="ส่วนลด %"
                  :disabled="item.status == 0"
                  class="border-radius-0"
                />
                <b-input-group-append is-text>
                  %
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="col">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="basic-group"
                  v-model="item.count"
                  type="number"
                  placeholder="จำนวนชุด"
                  disabled
                  class="border-radius-0"
                />
                <b-input-group-append is-text>
                  ชุด
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="col">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="basic-box"
                  v-model="item.quantity"
                  type="number"
                  placeholder="จำนวนกล่อง"
                  :disabled="item.status == 0"
                  class="border-radius-0"
                />
                <b-input-group-append is-text>
                  กล่อง
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="col">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="basic-box"
                  v-model="item.point"
                  type="number"
                  placeholder="จำนวนกล่อง"
                  :disabled="item.status == 0"
                  class="border-radius-0"
                />
                <b-input-group-append is-text>
                  แต้มแลกซื้อ
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="col">
              <button
                class="btn btn-gradient-primary"
                :disabled="item.status == 0"
                @click="Genbox(item)"
              >
                สร้างกล่องสุ่ม
              </button>
            </div>
          </div>
        </div>

        <div class="mt-1">
          <div class="row">
            <div class="col">
              <div class="card-cradit white">
                <div class="p-1">
                  <span>จำนวนกล่อง</span>
                  <h3>{{ item.gen }} <small>กล่อง</small></h3>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small>รวม {{ item.sumprice ? Commas(item.sumprice) : 0.00 }}</small><small>วอน</small> -->
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card-cradit white">
                <div class="p-1">
                  <span>จำนวนกล่องที่ขายได้</span>
                  <h3>{{ item.sell }} <small>กล่อง</small></h3>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small>รวม {{ item.sumprice ? Commas(item.sumprice) : 0.00 }}</small><small>วอน</small> -->
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card-cradit white">
                <div class="p-1">
                  <span>จำนวนกล่องคงเหลือ</span>
                  <h3>{{ item.remain }} <small>กล่อง</small></h3>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <!-- <small>รวม 0.00</small><small>วอน</small> -->
                </div>
              </div>
            </div>

            <!-- <div class="col">
              <div class="card-cradit white">
                <div class="p-1">
                  <span>ยอดเงินที่ได้รับ</span>
                  <h3>{{ Commas(item.sumprice) }} <small>₩</small></h3>
                </div>
                <div class="card-b d-flex justify-content-between">
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

    </div>
  </b-overlay>
</template>

<script>

import {
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  // BIconController,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    // BIconController,
  },
  data() {
    return {
      boxSixStatus: false,
      boxTwo2s: false,
      boxTwo4s: false,
      boxTwo6s: false,
      amountSix: null,
      amountTwo2: null,
      amountTwo4: null,
      amountTwo6: null,
      saleSix: null,
      saleTwo2: null,
      saleTwo4: null,
      saleTwo6: null,
      groupSix: 2,
      groupTwo2: 2,
      groupTwo4: 4,
      groupTwo6: 6,
      boxSix: null,
      boxTwo2: null,
      boxTwo4: null,
      boxTwo6: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      data: [],
    }
  },
  // created() {
  //   this.interval = setInterval(() => {
  //     // this.getUserslist()
  //     this.GetData()
  //   }, 15000)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    this.GetData()
  },
  methods: {
    SaveSetting(data) {
      // console.log(data)
      const Objs = {
        // eslint-disable-next-line no-underscore-dangle
        id: data._id,
        status: data.status,
        quantity: data.quantity,
        type: data.type,
        price: data.price,
        percent: data.percent,
        count: data.count,
        point: data.point,
      }
      this.$http
        .post('/lottery/Luckyboxsettings', Objs)
        .then(response => {
          this.Success(response.data.message)
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    Genbox(data) {
      if (!data.point || !data.quantity || !data.type || !data.price || !data.count) {
        this.$swal({
          icon: 'warning',
          title: '<h3 style="color: #141414">Warning</h3>',
          text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        this.$swal({
          title: 'แจ้งเตือน ?',
          text: 'ยืนยันที่จะสร้างกล่องสุ่มหรือไม่ ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
              quantity: data.quantity,
              type: data.type,
              price: data.price,
              percent: data.percent,
              count: data.count,
              point: data.point,
            }
            this.$http
              .post('/lottery/GenerateBox', obj)
              .then(response => {
                this.Success(response.data.message)
                this.GetData()
              })
              .catch(error => {
                this.SwalError(error.response.data.message)
              })
          } else {
            this.show = false
            this.$swal('ยกเลิก', 'ยกเลิกการสร้างกล่องสุ่ม!', 'error')
          }
        })
      }
    },
    GetData() {
      this.$http
        .get('/lottery/Luckyboxsettings/Index')
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.data = response.data
          for (let i = 0; i < this.data.length; i += 1) {
            if (this.data[i].status === 1) {
              this.data[i].status = true
            } else {
              this.data[i].status = false
            }
          }
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">Success</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">Error !</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
